body {
    background-color: #f0f2f5;
}
.logo {
    height: 28px;
    margin: 16px;
}
.login-logo {
    text-align: center;
}
.login-logo img {
    width: 100px;
    height: auto;
}
.login-box {
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #f0f2f5;
}
.site-layout-sub-header-background {
    background: #001529 !important;
}

.site-layout-background {
    background: #fff;
}

.btn-save {
    background-color: #52c41a !important;
    border-color: #52c41a !important;
}
.btn-edit {
    background-color: #faad14 !important;
    border-color: #faad14 !important;
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 99999;
    height: 10em;
    width: 10em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

footer {
    position: sticky;
    bottom: 0;
    padding: 0 !important;
    overflow: hidden;
    width: 100%;
    z-index: 999;
}

footer .nav-button {
    width: 20%;
    border-right: 1px solid #9ca490;
    border-bottom: 1px solid #9ca490;
    float: left;
    background-color: #001529;
    text-align: center;
}
footer .nav-button :hover {
    color: #fff;
}
footer .nav-child {
    padding: 14px 20px;
    display: block;
    color: #9ca490;
}
footer .nav-child :active {
    color: #fff;
}

